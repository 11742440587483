
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./components/home"
import Layout from "./components/layout/index"
import GridComponent from './components/GridComponent/grid ';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,

    children: [
      {
        path: "/",
        element: <Home/>,
      },
      {

      
        path: "grid/:id",
        element: <GridComponent/>,
    },



   
    ],
  }
  

]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(


  <RouterProvider router={router} />

      
);




