
import axios from "axios";
import React,{useState,useEffect} from "react";
import { useParams } from "react-router-dom";
import { Grid, Rating, Box,Typography} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import './grid.css'
 import Dialog from '@mui/material/Dialog';
 import ThumbUpIcon from '@mui/icons-material/ThumbUp';
 import CloseIcon from '@mui/icons-material/Close';
 import Stack from '@mui/material/Stack';
 import FiveStar from '../../assets/five-star.svg'
 import FourStar from '../../assets/four-star.svg'
 import ThreeStar from '../../assets/three-star.svg'
 import TwoStar from '../../assets/two-star.svg'
 import SingleStar from '../../assets/single-star.svg'
 import { Chip } from '@mui/material'
 import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
 import AcUnitIcon from '@mui/icons-material/AcUnit';
import { useNavigate } from 'react-router-dom';




function GridComponent() {




  const {id}=useParams()
  const [ idstate , setIdstate ] = useState(id) as any;

 useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const handleClick = (id:any) => {
    setIdstate(id)
    navigate1(`/grid/${id}`, { replace: true })
    window.scrollTo({top:0,behavior:"smooth"}); 
  }

 const navigate1=useNavigate()



  const [second, setsecond]=useState<any>([])


  useEffect( ()=>{
    async function initial(){
      const { data : { data }  } = await axios.get('https://dappstore.mo.vc/api/dapp-lists/'+idstate)
      setsecond(data)
     }
    initial()
   },[idstate])

  const [ runapi , setRunapi ] = useState(false) 


  
  const [ reviewpagination , setReviewpagination ] = useState<any>(
    {
    page : 1,
    total : 0,
    last_page : 0,
    reviews : {} as any,
    current_page : 1,
    data : [] as any
  }
);
 
   useEffect(()=>{
         
    
    async function initial(){ 
      const { data : { data , last_page , total , current_page }  } = await axios.get(`https://dappstore.mo.vc/api/dapp-list-reviews?dapp_id=${id}&page=${reviewpagination.page}`)
      var arr3 = [...reviewpagination?.data, ...data];
      arr3 = arr3.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.id === value.id 
          ))
        )
       setTimeout(()=>{
        setReviewpagination((prevstate : any)=>({...prevstate ,  data : arr3 , total : total , current_page : current_page , last_page : last_page }))
      },500); 
    }
    initial()
//eslint-disable-next-line
  },[reviewpagination.page])   

  const [open, setOpen] =useState(false);
  const [open1, setOpen1] =useState(false);
 
  const Closepopup=()=>{
    setOpen(false);






   
  }
  const Closepopup1 = () => {

    setOpen1(false);
    window.location.reload();
    
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };


  function Chipcolor(){
    return <Typography className='open-name' style={{
      fontSize: '12px',
      cursor: 'pointer',
      color: '#b33030',
      height: '18px',
      fontWeight:'500px',
      fontFamily: 'Poppins'
       }}>GET</Typography>
}

const [show,setShow]=useState(true)

const [ratingvalue, setRatingvalue] = React.useState(null);
const [titlevalue, setTitlevalue] = React.useState("");

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const result = event.target.value;
  setTitlevalue(result);
};

const [commentvalue, setCommentvalue] = React.useState("");

const onpagechange = () => { 

  let nextpage = {...reviewpagination}
  if(Number(nextpage?.last_page - 1 ) <= nextpage?.page){
    setShow(false)
  }
  setRunapi(!runapi)
   let nxtpge = Number(nextpage?.page) + 1   
   setReviewpagination((prevstate:any)=>({...prevstate , page : nxtpge} ));
}  

  
const onSubmitreview = () => {
 
  handleClickOpen1();
  Closepopup();

  let _titlevalue = titlevalue as any;
  let _commentvalue = commentvalue as any;
  let rating = ratingvalue as any
  let obj = {
    dapp_id  : `${id}`,
    review_title :_titlevalue,
    rating:rating,
    review_description:_commentvalue
  }
  console.log('submit review',obj);
 axios.post(`https://dappstore.mo.vc/api/reviews/store`,obj).then(res=>{
   
   
 }).catch(err=>{
  console.log(err);
  
 })

}
console.log(second?.social_media_links);


const tostringfn = () => { 
  return <span>{second?.reviews?.total_reviews}</span>
}


// console.log('link',second?.social_media_links[0]?.socialmediatype);

const link=`${second?.download_link}`
// const restrictSpecial = (event: React.ChangeEvent<HTMLInputElement>) =>{
//   var x = event.target.value;
//   if (x=='a')
//       return true;
//   else
//       return false;
// }

  return (
    <>

        
 
            


<div className="gridcomp">
  <h1 className="back-icon" onClick={()=>navigate1('/')}><ChevronLeftIcon/>Back</h1>
   
  <Grid container spacing={2} className='arif-twitter-flex' style={{paddingLeft:"20px",paddingRight:"20px",marginTop:"0px"}}>
    <Grid item xs={12} sm={8} md={8} lg={8} xl={8} >
      <div className="parrent-div">
        <div className="imgdiv" style={{maxWidth:'140px'}}>
         <img src={second?.logo} style={{borderRadius:"12px",marginTop:"8px"}} width={'100%'} alt='prod-logo'></img> 
        </div>
        <div className="textpara">
          <h6 className="title1">{second?.name}</h6>
          <h6 className='title2'>{second?.title2}</h6>
          <p className='title3'>{second?.title3}</p>

      {!link && <button  className="disbutton" disabled>Open</button>}
      {link && 
      <a href={ `${second?.download_link}` } 
              
      className="button1" download > Open</a>
      }

  
   
         

     
       
        </div>
      </div>
    </Grid>
    <Grid item xs={12} sm={4} md={4} lg={4} xl={3} mt={3} >
      <div className='icon-texts'>
        <Grid className='airnfts' item xs={12}>
           <h6>Airnfts</h6>
        </Grid>
        <Grid style={{display:'flex'}} item xs={12}>
            <Grid item xs={6} style={{textAlign:'center'}}>
              <div className="web-twitter" style={{margin:"0px auto"}}>   
                         <h1 className="radio-twit-icons">
               
              <a href={`${second.website}`} target="_blank"rel="noopener noreferrer"> <AcUnitIcon className='web-icon' /> </a> 
            </h1>
            <p ><a  href={`${second.website}`} style={{ textDecoration : 'none' , color : 'black'}} target="_blank"rel="noopener noreferrer"className='website'>Website</a></p>
     
            </div>


          
            </Grid>
            {
  second?.social_media_links?.map((res1:any)=>(

    <Grid item xs={6} style={{textAlign:'center'}}>
              

<div className="web-twitter" style={{margin:"0px auto"}}>   

<h1 className="radio-twit-icons">

<a href={`${res1.socialmedialink}`} target="_blank"rel="noopener noreferrer" ><img src={res1?.socialmedialogo} style={{borderRadius:"12px",marginTop:"8px"}} width={'100%'} className='binding-twit-img' alt='twitter-logo'></img> </a>
 
</h1>
<p className="web-twitter" ><a href={`${res1.socialmedialink}`} target="_blank"rel="noopener noreferrer" className='website'   style={{ textDecoration : 'none' , color : 'black'  }}>{res1?.socialmediatype}</a></p>
</div>
</Grid>
  ))
}    </Grid>
        </div>
        </Grid>


        

   
  </Grid>

  <div className="inside-div" style={{paddingLeft:"20px",paddingRight:"20px"}}> 
     <div className='section1-comp5'>         
          
             < h1 className="new">Info</h1>
             <hr></hr>
             </div>
             </div>

 
    <div className="info-heading" style={{paddingLeft:"20px",paddingRight:"20px"}}>
    <p>{second?.full_description}</p>
   

  </div>

  <div className="inside-div" style={{paddingLeft:"20px",paddingRight:"20px"}}> 
     <div className='section1-comp5'>         
             < h1 className="new">Ratings & Reviews</h1>
             <hr></hr>
             </div>
             </div>
  
<Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='grid-flex' style={{paddingLeft:"20px",paddingRight:"20px",}} >
  <Grid className='rateandreview'item xs={12} sm={6}  md={6} lg={3} >
  
    <div className="ratingheading">
      <div style={{display:'flex'}}>
    <h1>{second?.reviews?.reviews_average}</h1>
    <p className="para-review-point">Out of 5</p>
    </div>
{( (second?.reviews?.total_reviews===1) || (second?.reviews?.total_reviews===0)) ?
  <p>Based on {tostringfn()} Review</p> :   <p>Based on {tostringfn()} Reviews</p>
}


    </div>


  </Grid>
  <Grid className='starrating' item xs={12} sm={6}  md={6} lg={5} >

    
  <Stack mb={4}  >
  <Grid item xs={12}  style={{display:'flex'}}>
    <img src={FiveStar} alt="Wait" className="img-grid1" />
    

<Grid className="middle" item xs={6} sm={4}>

    <Box sx={{ width: '100%' }} >
      <LinearProgress variant="determinate" className="bar-container" value={second?.reviews?.five_star_rating_average} />
    </Box>
  </Grid>
  <div className="num-flex">
    <div className="side-right">
    <div className="numbers-grid">{second?.reviews?.five_star_rating_count}</div>
    </div>
  </div>
  
    </Grid>

    <Grid item xs={12} style={{display:'flex'}} >
      <img src={ThreeStar} alt="Wait" className="img-grid1" />
      

<Grid className="middle" item xs={6} sm={4}>
<Box sx={{ width: '100%' }} >
      <LinearProgress variant="determinate" className="bar-container" value={second?.reviews?.four_star_rating_average} />
    </Box>
  </Grid>
  
<div className="num-flex">
    <div className="side-right">
    <div className="numbers-grid">{second?.reviews?.four_star_rating_count}</div>
    </div>
  </div>
    </Grid>
 
    <Grid item xs={12} style={{display:'flex'}}>
      <img src={FourStar} alt="wait" className="img-grid1"/>
   
    <Grid className="middle" item xs={6} sm={4}>
    <Box sx={{ width: '100%' }} >
      <LinearProgress variant="determinate" className="bar-container" value={second?.reviews?.three_star_rating_average} />
    </Box>
  </Grid>
  
<div className="num-flex">
    <div className="side-right">
    <div className="numbers-grid">{second?.reviews?.three_star_rating_count}</div>
    </div>
  </div>
    </Grid>
 
    <Grid item xs={12} style={{display:'flex'}}>
      <img src={TwoStar} alt="Wait" className="img-grid1"/>
  
    <Grid className="middle" item xs={6} sm={4}>
    <Box sx={{ width: '100%' }} >
      <LinearProgress variant="determinate" className="bar-container" value={second?.reviews?.two_star_rating_average} />
    </Box>
  </Grid>
  

<div className="num-flex">
    <div className="side-right">
    <div className="numbers-grid">{second?.reviews?.two_star_rating_count}</div>
    </div>
  </div>
    </Grid>
 
    <Grid item xs={12} style={{display:'flex'}}>
    <img src={SingleStar} alt="Wait" className="img-grid1"/>

  
 
 <Grid className="middle" item xs={6} sm={4}>
 <Box sx={{ width: '100%' }} >
      <LinearProgress variant="determinate" className="bar-container" value={second?.reviews?.one_star_rating_average} />
    </Box>
    
  </Grid>
  <div className="num-flex">
    <div className="side-right">
    <div className="numbers-grid">{second?.reviews?.one_star_rating_count}</div>
    </div>
  </div>
    </Grid>
 
 
 

    </Stack>
  


  </Grid>



  
  <Grid className='review2' item xs={12} sm={6}  md={6} lg={4} mb={4} >
   
    <div className="ratingheading2">
   <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy</p>
   <div className="seeall-btn">   <button onClick={handleClickOpen} className="seeall-btn">Write a Review</button>
   </div>

   <Dialog 
   
        open={open}
       
      >
        <div className='dialogbox'>
          <div className='dialogheader'>
        <h1 className='dialogheading'>Write a Review</h1>
<p className="dialog-first-para">Lorem ipsum dolor sit amet, consetetur</p>
<div  className='icon'>
<img src={second?.logo} alt='prod-logo' style={{borderRadius:"12px"}}></img>
</div>
<h2 className='dialogname'>{second?.name}</h2>
</div>
<div className='staricon'>
<p>Rate your experience</p>
  <Stack spacing={1} >
      <Rating   value={ratingvalue} className='star-rating-icon-review' name="half-rating" defaultValue={2} onChange={(event, newValue : any) => {
       setRatingvalue(newValue);
  }} />
  </Stack>

</div>


<Grid item xs={4}>

    <div className="ratingheading1">
  
 
  

    </div>

  </Grid>
         <div className="forms">
         <form autoComplete="off" >
        <label htmlFor="name" >Title</label><br/>
        <input className="form-control1" id="name" placeholder="Write a Title"       onChange={handleChange}/> 
        <label htmlFor="email">Review (Optional)</label><br/>
 
          <textarea name="textarea" id="review-id" cols={50} rows={5} maxLength={500}placeholder="Write a Review" onChange={e=>setCommentvalue(e.target.value)}></textarea>
      
          

      
      <input  onClick={()=>onSubmitreview()} className='dialogboxbutton' value="Submit"  disabled={ (ratingvalue==null) || (!titlevalue) } />
      </form>
      </div>
     
     
   
      
         
          <h3 className='closepopup'onClick={Closepopup}><CloseIcon/></h3> 
        
          </div>

      </Dialog>

      <Dialog
        open={open1}
      >
        <div className="dialogbox1">
          <div className='dialogheader1'>
            <div className="dialog-icon">
            <h2><ThumbUpIcon/></h2>
            </div>
        <h1 style={{marginTop:15}}>Thanks for your Review</h1>
       
        <h3 className='closepopup' onClick={Closepopup1}><CloseIcon/></h3>
        <button  onClick={()=>{Closepopup1();}}>Done</button>
        </div>
        </div>
    
        </Dialog>

    </div>
  </Grid>
  </Grid>





  <Grid container spacing={3} style={{paddingLeft:"20px",paddingRight:"20px",paddingBottom:"5rem"}}>
  
  {
  reviewpagination?.data?.length > 0 && reviewpagination?.data?.map((res:any)=>(
   
      <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography component={"div"} className="card-div"> 
              <Typography component={'h1'} className='dynamic-r-title'>{res?.review_title}</Typography>
            <Typography className="inside-flex">
          <Grid className='inside-flex1'>
            <Typography className="ratingheading1" style={{marginRight:'15px'}}>
            <Stack spacing={1}>
   <Rating   value={res?.ratings} className='star-rating-icon' name="half-rating"/> 
  </Stack>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h5 className="dynamic-ratings">{res?.ratings} out of 5</h5>
          </Grid>
            </Typography>
              <Typography component={'p'} className='dynamic-text'>{res?.review_description}</Typography>
              <Typography component={"p"} className="last-para">{res?.created_on} </Typography>
            </Typography>

      </Grid>

  ))
}

    <Grid item xs={12} style={{textAlign: "center"}} >
      {(second?.reviews?.total_reviews > 4 && show )&& 

<button onClick={()=> onpagechange()} className='show-btn'> Show More </button>

      }
   
    </Grid>

</Grid> 



  
</div>
 <div className="last-grid-page">
 <div className="last-grid-flex">
  <h1>You May also like</h1>

 </div>

<Grid container  spacing={2} style={{marginTop:"10px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px",}} > 
              { second?.related_dapps?.length > 0  && second?.related_dapps?.map((res:any)=>( 
                <>
                     
                   
        <Grid className='onclick-hover' item xs={12} sm={12} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'space-between',cursor:'pointer' }}  onClick={()=>handleClick(res?.id)}>

          <Grid item xs={2} sm={2} md={2} lg={2} mr={2} style={{ maxWidth: "72px" }}>
           {
          res?.image &&
          <img style={{borderRadius:"12px"}}  src={res?.image} width={'100%'} alt='prod-icon'/>
        } 
        
           
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            <Typography  variant="h6" style={{
              color: '#242424',
              margin: '0px',
              fontFamily: 'Poppins',
              fontSize: '18px',
            }} className='data-name'>

              {res?.name}</Typography>
            <Typography className='data-desc' style={{
             margin: '0px',
             color: '#56687B',
             fontSize: '12px',
             fontFamily: 'Poppins',
            }}>{res?.description}</Typography>
          </Grid>
          
     
         <Grid className='chip-btn' item xs={2} sm={2} md={2} lg={2} style={{ marginTop: '1rem', textAlign: 'center' }} ><Chip label={<Chipcolor/>} className='chipbtn'/></Grid>
        
      
      </Grid>
                   
                </>
              )) }
            
          </Grid>

 </div>
</>
  );
}

export default GridComponent;




