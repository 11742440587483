
import "./category.css"
import React, { useState, useEffect,useContext } from 'react'
import axios from "axios";
import { Grid, Stack, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import './style.css'
import { useNavigate } from 'react-router-dom';
import ScrollContext from "../../redux/scrollev"
import {Skeleton} from '@mui/material';

function Chipcolor() {
  return <Typography className='open-name' style={{
    fontSize: '12px',
    cursor: 'pointer',
    color: '#b33030',
    height: '18px',
    fontWeight:'500px',
    fontFamily: 'Poppins'
  }}>GET</Typography>
}
function ChildrenGrid1(props:any) {
  const { data } = props
  const navigate = useNavigate();
  const handleClick = (id:any) => {
    navigate('/grid/'+id)
  }

  return (
    <>
      <Grid className='childrengrid1'>
        < Grid  item xs={12} sm={12}>
        {
          data?.feature_image &&
          <img style={{borderRadius:"12px",width:"100%"}}  src={data?.feature_image}  alt='prod-logo'/>
        }
                 
        </Grid>
        <Grid className='onclick-hover' item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px',cursor:'pointer' }}  onClick={()=>handleClick(data.id)}>

          <Grid item xs={2} mr={2} style={{ maxWidth: '56px' }}>
          {
          data?.image &&
          <img  src={data?.image} style={{ width: '100%',borderRadius:"12px"}} alt='prod-icon' />
        }
           
          </Grid>
          <Grid item xs={7.5}>
            <Typography style={{
              color: '#242424',
              margin: '0px',
              fontFamily: 'Poppins',
              fontSize: '18px',
            }} className='data-name'>

              {data?.name}</Typography>
            <Typography className='data-desc' style={{
              margin: '0px',
              color: '#56687B',
              fontSize: '12px',
              fontFamily: 'Poppins',
            }}>{data?.description}</Typography>
          </Grid>

          <Grid className='chip-btn' item xs={2.5} style={{ marginTop: '1rem', textAlign: 'center' }}><Chip label={<Chipcolor />} className='chipbtn' onClick={()=>handleClick(data.id)} /></Grid>

        </Grid>
      </Grid>

    </>

  );
}
function ChildrenGrid2(props: any) {
  const { data } = props
  const navigate = useNavigate();
  const handleClick = (id:any) => {
    navigate('/grid/'+id)
  }
  return (
    <>
      <Grid className='onclick-hover' item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'space-between',cursor:'pointer' }}  onClick={()=>handleClick(data.id)}>
        <Grid item xs={2} sm={2} md={2} lg={2} mr={2} style={{ maxWidth: "72px" }} >
          <img src={data?.image} style={{ width: '100%', borderRadius: '12px' }} alt='prod-logo' />
        </Grid>
        <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5}>
          <Typography variant="h6" className='data-name' style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '18px',
          }}>{data?.name}</Typography>
          <Typography className='data-desc' style={{
            margin: '0px',
            color: '#56687B',
            fontSize: '12px',
            fontFamily: 'Poppins',
          }}>{data?.description}</Typography>
        </Grid>
        <Grid className='chip-btn' item xs={2.5} style={{ marginTop: '1rem', textAlign: 'center' }}><Chip label={<Chipcolor />} className='chipbtn' onClick={()=>handleClick(data.id)} /></Grid>

      </Grid>
    </>

  );
}

export default function Category() {
  const [post,setPosts]=useState<any>([])
  const [isLoading,setisLoading]=useState<any>(true)

   useEffect( ()=>{

    setTimeout(()=>{
      setisLoading(false)

    },300)


    async function initial(){
      const { data : datalist  } = await axios.get('https://dappstore.mo.vc/api/dapp-lists')
 
  
     setPosts(datalist)
  

     console.log('datalist',datalist);
    }
    initial()
   },[])
 console.log('post',post);
  const [scroll ] = useContext(ScrollContext) as any;
  console.log('scrolllll',scroll);

 useEffect(()=>{
    
 
    const titleElements: HTMLElement | any  = document?.getElementById(scroll)
    console.log(titleElements);


    const myElement: HTMLElement | any = titleElements?.scrollIntoView({ behavior: 'smooth', inline:"nearest"});
    console.log(myElement);
    
    

    

  },[scroll])
   
  return (
    <>
      <Grid container spacing={2}>
  

        {post?.data?.map((post:any , hn :number) => (
          <>
          {


isLoading?(

  <Grid item md={12} sm={12} lg={12} xl={12} id={`section${post.id}`}>
  <div className='section1-comp5'>
  
   
    {
      (post?.id !== 0) ?
      <>
        < Typography className='home-parent' style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '22px', color: '#242424', whiteSpace: 'nowrap' }}>  <Skeleton  width={100} style={{ marginLeft:'15px'}}/>
</Typography>
      <hr></hr>
      </>
     : null
    }
  
  </div>
</Grid>
  
):(
         


            <Grid item md={12} sm={12} lg={12} xl={12} id={`section${post.id}`}>
              <div className='section1-comp5'>
              
               
                {
                  (post?.id !== 0) ?
                  <>
                    < Typography className='home-parent' style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '22px', color: '#242424', whiteSpace: 'nowrap' }}>{post?.parent }</Typography>
                  <hr></hr>
                  </>
                 : null
                }
              
              </div>
            </Grid>
)
}
<Stack direction='row' spacing={1} sx={{width:'100%',marginTop:"12px"}}/>




              {post?.children.length > 0 && post.type === 'grid1' && post.children.map((child: any,i:number) => (
              <>

{


isLoading?(
  

  <Grid className='childrengrid1'>
        < Grid  item xs={12} sm={12}>

<Skeleton variant="rectangular" style={{ margin: '20px'}} width={320}  height={140}/>
                 
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px',marginBottom:"10px" }}>

          <Grid item xs={2} mr={2} style={{ maxWidth: '56px' }}>
          {
         
          <Skeleton variant="circular" width={50}  height={50} style={{ width: '100%',  borderRadius:"12px",}} />
        }


           
          </Grid>
          <Grid item xs={7.5}>
            <Typography style={{
              color: '#242424',
              
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 'bold',
            }} className='data-name'>

 <Skeleton variant="rectangular" height={10}/>
</Typography>
            <Typography className='data-desc' style={{
              marginTop: '20px',
              color: '#56687B',
              fontSize: '12px',
              fontFamily: 'Poppins',
              
            }}> <Skeleton variant="rectangular" height={10}/>
            </Typography>
          </Grid>

        </Grid>
      </Grid>



):(


                <Grid item xs={12} sm={12} md={4} >
                  <ChildrenGrid1 data={child} />
                </Grid>

)
}
              </>
            ))} 
            {post?.children.length > 0 && post?.type === 'grid2' && post?.children.map((child: any,i:number) => (

              
              <>

{


isLoading?(
 

<Grid item xs={12} sm={12} md={12} lg={4} sx={{ display: 'flex', justifyContent: 'left' }}>
        <Grid item xs={2} sm={2} md={2} lg={2} mr={2} style={{ maxWidth: "72px" }} >
        <Skeleton variant="circular" width={50}  height={50} style={{  borderRadius:"12px",}} />
        </Grid>
        <Grid item xs={7.5} sm={7.5} md={7.5} lg={7.5}>
          <Typography variant="h6" className='data-name' style={{
            color: '#242424',
            margin: '0px',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 'bold',
          }}><Skeleton variant="rectangular" height={10}/></Typography>
          <Typography className='data-desc' style={{
            marginTop: '15px',
            color: '#56687B',
            fontSize: '12px',
            fontFamily: 'Poppins',
          }}><Skeleton variant="rectangular" height={10}/></Typography>



          


        </Grid>
        

      </Grid>

):(
              
                <Grid item xs={12} sm={12} md={4} >
                  <ChildrenGrid2 data={child} />
                </Grid>
               
                
               )
              }


                
              </>

              




            ))}  


            
          </>
        ))}

      </Grid>
    </>
  )
}


